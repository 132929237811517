/**
 * 模块国际化语言配置文件
 */
const systemManagementEn = {
    'sysCtrlParam': 'sysCtrlParam',
    'databaseManage': 'databaseManage',
    'dataDictionary': 'dataDictionary',
    'translationTable': 'translationTable',
    'translationDataDictionary': 'translationDataDictionary',
    'masterDataTranslation': 'masterDataTranslation',
    'mqManager': 'mqManager'
};
const systemManagementZh = {
    'sysCtrlParam': '系统控制参数',
    'databaseManage': '数据库管理',
    'dataDictionary': '数据字典',
    'translationTable': '表翻译维护',
    'translationDataDictionary': '数据字典翻译',
    'master-data-translation': '主数据翻译',
    'mqManager': 'mq管理'
};
const systemManagementJa = {
    'sysCtrlParam': 'sysCtrlParam',
    'databaseManage': 'databaseManage',
    'dataDictionary': 'dataDictionary',
    'translationTable': 'translationTable',
    'translationDataDictionary': 'translationDataDictionary',
    'masterDataTranslation': 'masterDataTranslation',
    'mqManager': 'mqManager'
};
const warehouseRelatedEn = {
    'warehouse': 'Warehouse',
    'stevedoringTeam': 'stevedoringTeam'
};
const warehouseRelatedZh = {
    'warehouse': '仓库',
    'stevedoringTeam': '装卸组'
};
const warehouseRelatedJa = {
    'warehouse': 'Warehouse',
    'stevedoringTeam': 'stevedoringTeam'
};
const commodityRelatedEn = {
    'commodityCat': 'commodityCat',
    'batch': 'batch',
    'commodityPack': 'commodityPack',
    'materialGroup': 'MaterialGroup',
    'commodity': 'commodity',
    'barcode': 'Barcode',
    'barcodeRule': 'BarcodeRule',
    'looseParts': 'LooseParts'
};
const commodityRelatedZh = {
    'commodityCat': '商品分类',
    'batch': '批次属性',
    'commodityPack': '包装换算关系',
    'materialGroup': '商品类别/组表',
    'commodity': '商品',
    'barcode': '条码规则',
    'barcodeRule': '条码规则组',
    'looseParts': '套散件'
};
const commodityRelatedJa = {
    'commodityCat': 'CommodityCat',
    'batch': 'batch',
    'commodityPack': 'CommodityPack',
    'materialGroup': 'MaterialGroup',
    'commodity': 'commodity',
    'barcode': 'Barcode',
    'barcodeRule': 'BarcodeRule',
    'looseParts': 'LooseParts'
};
const addressRelatedEn = {
    'stationDistance': 'stationDistance',
    'locationManage': 'locationManage',
    'admLayout': 'admLayout'
};
const addressRelatedZh = {
    'stationDistance': '站点距离',
    'locationManage': '地点',
    'admLayout': '行政规划'
};
const addressRelatedJa = {
    'stationDistance': 'StationDistance',
    'locationManage': 'LocationManage',
    'admLayout': 'AdmLayout'
};
const customerRelatedEn = {
    'client': 'client',
    'contract': 'contract',
    'customerGroup': 'customerGroup',
    'forShipper': 'forShipper'
};
const customerRelatedZh = {
    'client': '客户',
    'contract': '合同',
    'customerGroup': '客户组',
    'forShipper': '收发货人'
};
const customerRelatedJa = {
    'client': 'client',
    'contract': 'contract',
    'customerGroup': 'CustomerGroup',
    'forShipper': 'ForShipper'
};
const transportRelatedEn = {
    'fenceConfig': 'fenceConfig',
    'nocarPlatform': 'nocarPlatform',
    'carType': 'carType'
};
const transportRelatedZh = {
    'fenceConfig': '围栏配置',
    'nocarPlatform': '无车平台',
    'carType': '车型'
};
const transportRelatedJa = {
    'fenceConfig': 'FenceConfig',
    'nocarPlatform': 'NocarPlatform',
    'carType': 'CarType'
};
const mappingControlEn = {
    'customerMapping': 'CustomerMapping',
    'itemMapping': 'ItemMapping',
    'itemStatusMapping': 'ItemStatusMapping',
    'warehouseMapping': 'WarehouseMapping',
    'orderTypeMapping': 'OrderTypeMapping'
};
const mappingControlZh = {
    'customerMapping': '货主对照',
    'itemMapping': '商品对照',
    'itemStatusMapping': '商品状态对照',
    'warehouseMapping': '运作单位对照',
    'orderTypeMapping': '订单类型对照'
};
const mappingControlJa = {
    'customerMapping': 'CustomerMapping',
    'itemMapping': 'ItemMapping',
    'itemStatusMapping': 'ItemStatusMapping',
    'warehouseMapping': 'WarehouseMapping',
    'orderTypeMapping': 'OrderTypeMapping'
};
const orderControlEn = {
    'reservationCtrl': 'ReservationCtrl',
    'demolitionCtrl': 'DemolitionCtrl',
    'consolidationToCtrl': 'ConsolidationToCtrl',
    'consolidationRuleCtrl': 'ConsolidationRuleCtrl',
    'aginCtrl': 'AginCtrl'
};
const orderControlZh = {
    'reservationCtrl': '预约控制',
    'demolitionCtrl': '拆单控制',
    'consolidationToCtrl': '集拼下发控制',
    'consolidationRuleCtrl': '集拼规则控制',
    'aginCtrl': '时效产品'
};
const orderControlJa = {
    'reservationCtrl': 'ReservationCtrl',
    'demolitionCtrl': 'DemolitionCtrl',
    'consolidationToCtrl': 'ConsolidationToCtrl',
    'consolidationRuleCtrl': 'ConsolidationRuleCtrl',
    'aginCtrl': 'AginCtrl'
};
const rulesRelatedEn = {
    'autoConfig': 'AutoConfig',
    'sendConfig': 'SendConfig',
    'customerContract': 'CustomerContract',
    'customerPrescription': 'CustomerPrescription'
};
const rulesRelatedZh = {
    'autoConfig': '自动装车配置',
    'sendConfig': '自动下发配置',
    'customerContract': '货主合同配置',
    'customerPrescription': '货主时效'
};
const rulesRelatedJa = {
    'autoConfig': 'AutoConfig',
    'sendConfig': 'SendConfig',
    'customerContract': 'CustomerContract',
    'customerPrescription': 'CustomerPrescription'
};
const baseEn = {
    'ossFile': 'ossFile',
    'messageCenter': 'messageCenter',
    'manualSync': 'manualSync'

};
const baseZh = {
    'ossFile': '个人文件列表',
    'messageCenter': '消息中心',
    'manualSync': '基础数据同步'
};
const baseJa = {
    'ossFile': 'ossFile',
    'messageCenter': 'messageCenter',
    'manualSync': 'manualSync'
};

// 英文
const en = {
    'mappingSync': 'MappingSync',
    'tenantBusiness': 'tenantBusiness',
    'dataInstanceManage': 'dataInstanceManage',
    'manualSync': 'manualSync',
    'wxBody': 'wxBody',
    'consumerMange': 'consumerMange',
    'lineNetwork': 'lineNetwork',
    'mipIframe': 'mipIframe',
    ...systemManagementEn,
    ...warehouseRelatedEn,
    ...commodityRelatedEn,
    ...addressRelatedEn,
    ...customerRelatedEn,
    ...transportRelatedEn,
    ...mappingControlEn,
    ...orderControlEn,
    ...rulesRelatedEn,
    ...baseEn
};
// 中文
const zh = {
    'mappingSync': '数据映射',
    'tenantBusiness': '租户业务类型关联',
    'dataInstanceManage': '数据库实例',
    'manualSync': '基础数据同步',
    'wxBody': '微信主体',
    'consumerMange': '消费者管理',
    'lineNetwork': '物流网',
    'mipIframe': '合同流程审批',
    ...systemManagementZh,
    ...warehouseRelatedZh,
    ...commodityRelatedZh,
    ...addressRelatedZh,
    ...customerRelatedZh,
    ...transportRelatedZh,
    ...mappingControlZh,
    ...orderControlZh,
    ...rulesRelatedZh,
    ...baseZh
};
// 日文
const ja = {
    'mappingSync': 'MappingSync',
    'tenantBusiness': 'tenantBusiness',
    'dataInstanceManage': 'dataInstanceManage',
    'manualSync': 'manualSync',
    'wxBody': 'wxBody',
    'consumerMange': 'consumerMange',
    'lineNetwork': 'lineNetwork',
    'mipIframe': 'mipIframe',
    ...systemManagementJa,
    ...warehouseRelatedJa,
    ...commodityRelatedJa,
    ...addressRelatedJa,
    ...customerRelatedJa,
    ...transportRelatedJa,
    ...mappingControlJa,
    ...orderControlJa,
    ...rulesRelatedJa,
    ...baseJa
};
export default {
    zh,
    en,
    ja
};
